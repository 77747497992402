<template>
  <moe-page title="查看九宫格抽奖">
    <moe-card class="mb-20" :autoHeight="true">
      <template slot="header">
        <moe-steps :active="active" :stepsList="[{ title: '基本信息设置' }, { title: '奖品设置' }, { title: '转盘设置' }]"></moe-steps>
      </template>

      <moe-form
        class="default-form"
        ref="luckyGridForm"
        :showBack="false"
        :showClose="true">
        <div style="width: 50%;" class="margin-0-auto" v-if="active === 0">
          <moe-describe-list title="" :col="1">
            <moe-describe-item label="活动名称"> {{ luckyGridData.name }} </moe-describe-item>
            <moe-describe-item label="活动时间">{{ luckyGridData.startTime }} 至 {{ luckyGridData.endTime }} </moe-describe-item>
            <moe-describe-item label="抽奖次数">
              <div v-if="luckyGridData.buySend" class="df aic wsn">
                {{ `订单满 ${luckyGridData.sendCondition} 元 获得一次抽奖机会，每人最多获得 ${luckyGridData.sendCount} 次` }}
              </div>
              <div v-else>无</div>
            </moe-describe-item>
          </moe-describe-list>
        </div>

        <div v-if="active === 1">
          <div class="fwb mb-20 font-20">奖品设置</div>
          <div style="height: 900px;min-height: 900px;" class="df fdc">
            <moe-table
              :maxHeight="900"
              :stripe="true"
              :data="luckyGridData.itemList"
              :numberShow="false"
              :mode="false"
              emptyText="请添加奖品">
              <el-table-column label="奖品名称" prop="name" min-width="120" :show-overflow-tooltip="true" />
              <el-table-column label="奖品类型" min-width="150" :formatter="formatterPrizeType" />
              <el-table-column label="发放总量" min-width="100" prop="total" />
              <el-table-column label="转盘图片">
                <template slot-scope="{ row }">
                  <moe-image :src="row.image" width="80px" height="80px" />
                </template>
              </el-table-column>
            </moe-table>
          </div>
        </div>

        <div v-if="active === 2">
          <div class="fwb mb-20 font-20">转盘设置</div>
          <div style="height: 900px;min-height: 900px;" class="df fdc">
            <moe-table
              :maxHeight="900"
              :stripe="true"
              :data="luckyGridData.itemConfigList"
              :numberShow="false"
              :mode="false"
              emptyText="请添加奖品">
              <el-table-column label="转盘位置" :formatter="formatterIndex" />
              <el-table-column label="奖品" prop="lotteryItemName" min-width="120" :show-overflow-tooltip="true" />
              <el-table-column label="转盘图片" min-width="200">
                <template slot-scope="{ row }">
                  <moe-image v-if="row.image" :src="row.image" width="80px" height="80px" />
                </template>
              </el-table-column>
              <el-table-column label="转到概率" :formatter="formatterRate" />
            </moe-table>
          </div>
        </div>

        <template slot="tool">
          <el-button v-if="active > 0" type="primary" plain icon="el-icon-back" @click="active -= 1">上一步</el-button>
          <el-button v-if="active < 2" type="primary" icon="el-icon-right" @click="active += 1">下一步</el-button>
        </template>
      </moe-form>

    </moe-card>
  </moe-page>
</template>

<script>
export default {
  name: 'LotteryManageLuckyGridDetail',
  data() {
    return {
      luckyGridData: {},
      active: 0
    }
  },
  methods: {
    formatterPrizeType({ prizeType }) {
      return this.$moe_format.getPrizeTypeList(prizeType);
    },
    formatterIndex(row, column, cellValue, index) {
      return index + 1;
    },
    formatterRate({ rate }) {
      return rate >= 0 ? `${rate} %` : '-'
    },
    /** 获取抽奖活动详情 */
    async req_getLotteryDetail() {
      if (this.$route.query.id) {
        const { code, result, message } = await this.$moe_api.LUCKYGRID_API.getLotteryDetail({ id: this.$route.query.id });
        if (code === 200) {
          let { id, name, startTime, endTime, buySend, sendCondition, sendCount, itemList, itemConfigList } = result;
          this.luckyGridData = {
            id,
            name,
            startTime,
            endTime,
            buySend,
            sendCondition,
            sendCount,
            itemList,
            itemConfigList: itemConfigList?.length ? itemConfigList.map((item) => {
              let findItem = itemList.find(({ id }) => item.lotteryItemId === id);
              return {
                ...item,
                image: findItem?.image,
                lotteryItemName: findItem?.name
              }
            }) : Array.from({ length: 8 }, (_, index) => ({
              lotteryItemId: '',
              rate: '',
              image: '',
              lotteryItemName: '',
            })),
          };

          this.datetime = [startTime, endTime];
        } else {
          this.$moe_msg.error(message);
        }
      }
    },
  },
  mounted() {
    this.req_getLotteryDetail();
  },
}
</script>